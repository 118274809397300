import { each } from '../helpers/array';

export default class Entities {
  /**
   * Entities constructor
   * @param {String} name name of the entities set - events prefix
   * @param {String} selector single element selector
   * @param {Function} creator single object creator function
   * @param {Function} cleaner single object cleaner function
   * @param {Object} options additional options
   */
  constructor(
    name,
    selector,
    creator = () => ({}),
    cleaner = () => {},
    {
      filter = () => true,
    } = {},
  ) {
    this.name = name;
    this.selector = selector;

    this.creator = creator;
    this.cleaner = cleaner;

    this.filter = filter;

    this.entitiesArr = [];
    this.createNew();

    this.bindEvents();
  }

  createNew(prevEntitiesArr = []) {
    const rawElementsArr = document.querySelectorAll(this.selector);
    if (rawElementsArr.length <= 0) return prevEntitiesArr;

    const elementsArr = Array.prototype.filter.call(rawElementsArr, this.filter);

    this.entitiesArr = [];
    each(elementsArr, (element) => {
      const prevEntity = prevEntitiesArr.find(entity => entity.element === element);
      if (prevEntity !== undefined) {
        this.entitiesArr.push(prevEntity);
        return true;
      }

      this.entitiesArr.push({
        element,
        entityObj: this.creator(element),
      });
    });

    return this.entitiesArr;
  }

  destroyAll() {
    each(this.entitiesArr, (entity) => {
      this.cleaner(entity);
      entity.entityObj = null;
    });
    this.entitiesArr = [];
  }

  bindEvents() {
    this.onCreateNewEvent = this.onCreateNewEvent.bind(this);
    window.addEventListener(`${this.name}CreateNew`, this.onCreateNewEvent);

    this.onDestroyAllEvent = this.onDestroyAllEvent.bind(this);
    window.addEventListener(`${this.name}DestroyAll`, this.onDestroyAllEvent);

    this.onRefreshEvent = this.onRefreshEvent.bind(this);
    window.addEventListener(`${this.name}Refresh`, this.onRefreshEvent);
  }

  onCreateNewEvent() {
    this.createNew(this.entitiesArr);
  }

  onDestroyAllEvent() {
    this.destroyAll();
  }

  onRefreshEvent() {
    this.refresh();
  }

  refresh() {
    this.destroyAll();
    this.createNew();
  }

  getEntityByEl(element) {
    return this.entitiesArr.find(entity => entity.element === element);
  }

  getAll() {
    return this.entitiesArr;
  }

  forEachEntity(callback) {
    each(this.entitiesArr, callback);
  }
}
