import Swiper from 'swiper';
import { Pagination, Navigation } from 'swiper/modules';
import Entities from './Entities';

class TextPhotosSliderEntity {
  constructor(rootEl) {
    if (!this.setVars(rootEl)) return;

    this.initSwiper();
  }

  setVars(rootEl) {
    this.rootEl = rootEl;
    if (!this.rootEl) return;

    this.sliderEl = this.rootEl.querySelector('[data-text-photos-slider]');
    if (!this.sliderEl) return;

    this.nextButtonEl = this.rootEl.querySelector('[data-text-photos-next]');
    if (!this.nextButtonEl) return;

    this.prevButtonEl = this.rootEl.querySelector('[data-text-photos-prev]');
    if (!this.prevButtonEl) return;

    this.paginationEl = this.rootEl.querySelector('[data-text-photos-pagination]');
    if (!this.paginationEl) return;

    return true;
  }

  initSwiper() {
    this.swiper = new Swiper(this.sliderEl, {
      modules: [Pagination, Navigation],
      slidesPerView: 1,
      spaceBetween: 100,
      speed: 1000,
      allowTouchMove: true,
      navigation: {
        nextEl: this.nextButtonEl,
        prevEl: this.prevButtonEl,
      },
      pagination: {
        el: this.paginationEl,
        clickable: true,
      },
    });

  }

  destroy() {
    if (this.swiper) this.swiper.destroy();
  }
}

export default class TextPhotosSlider {
  constructor() {
    this.entities = new Entities(
      'TextPhotosSlider',
      '[data-text-photos]',
      TextPhotosSlider.initSingle,
      TextPhotosSlider.destroySingle,
    );
  }

  static initSingle(element) {
    return new TextPhotosSliderEntity(element);
  }

  static destroySingle({ entityObj }) {
    entityObj.destroy();
  }
}
