import Entities from './Entities';
import { isRtl } from '../helpers/document';

export default class InputLabel {
  constructor() {
    this.entities = new Entities(
      'InputLabel',
      '[data-input-label]',
      InputLabel.initSingle,
    );
  }

  static initSingle(containerEl) {
    const inputEl = containerEl.querySelector('[data-input-label-input]');
    const lineEl = containerEl.querySelector('[data-input-line]');
    const label = containerEl.querySelector('label');
    if (!label) return false;
    const className = containerEl.getAttribute('data-input-label');

    function onChange(e) {
      InputLabel.setInputFilled(e.target.value, containerEl, className);
    }

    function setLine() {
      const labelWidth = label.getBoundingClientRect().width;
      // eslint-disable-next-line no-magic-numbers
      const labelOffset = isRtl ? 20 : label.offsetLeft;
      const width = labelWidth + labelOffset;

      lineEl.style.width = `calc(100% - ${width}px)`;
    }

    function resetLine() {
      if (inputEl.value) return;
      lineEl.style.width = '100%';
    }

    function updateInput(e) {
      onChange(e);
      resetLine();
    }

    window.addEventListener('load', () => {
      const select = containerEl.querySelector('.select__button');
      if (select) {
        select.addEventListener('focus', setLine);
        select.addEventListener('focusout', resetLine);
      }
    });

    inputEl.addEventListener('keyup', onChange);
    inputEl.addEventListener('input', onChange);
    inputEl.addEventListener('change', onChange);
    inputEl.addEventListener('focus', setLine);
    inputEl.addEventListener('focusout', resetLine);
    window.addEventListener('InputUpdate', updateInput);

    InputLabel.setInputFilled(inputEl.value, containerEl, className);
  }

  static setInputFilled(value, containerEl, className) {
    if (value) {
      containerEl.classList.add(className);
    } else {
      containerEl.classList.remove(className);
    }
  }
}
