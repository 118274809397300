import Swiper from 'swiper';
import { Pagination, Navigation, EffectCreative } from 'swiper/modules';
import { scrollTo } from './ScrollTo';

export default class HeroSlider {

  constructor() {
    if (!this.setVars()) return;
    this.bindEvents();
    this.initSwiper();
  }

  setVars() {
    this.sliderEl = document.querySelector('[data-hero-slider]');
    if (!this.sliderEl) return;

    this.paginationEl = this.sliderEl.querySelector('[data-hero-slider-pagination]');
    this.nextButtonEl = this.sliderEl.querySelector('[data-hero-slider-next]');
    this.prevButtonEl = this.sliderEl.querySelector('[data-hero-slider-prev]');

    this.scrollDown = this.sliderEl.querySelector('[data-hero-scroll-down]');

    return true;
  }

  initSwiper() {
    this.swiper = new Swiper(this.sliderEl, {
      modules: [Pagination, Navigation, EffectCreative],
      slidesPerView: 1,
      speed: 1000,
      effect: 'creative',
      creativeEffect: {
        prev: {
          shadow: true,
          translate: [0, 0, -1],
        },
        next: {
          translate: ['100%', 0, 0],
        },
      },
      navigation: {
        nextEl: this.nextButtonEl,
        prevEl: this.prevButtonEl,
      },
      pagination: {
        el: this.paginationEl,
        clickable: true,
      },
    });
  }

  bindEvents() {
    this.scrollDown.addEventListener('click', this.onScroll);
  }

  // eslint-disable-next-line class-methods-use-this
  onScroll() {
    scrollTo(window.innerHeight);
  }

}
