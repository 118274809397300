/* eslint-disable arrow-body-style */
import Swiper from 'swiper';
import {
  Navigation, Thumbs, Controller, EffectFade,
} from 'swiper/modules';
import { each } from '../helpers/array';
import { setSlideAttrs } from '../helpers/slider';
import Entities from './Entities';

class AuthoritySliderEntity {
  constructor(rootEl) {
    if (!this.setVars(rootEl)) return;

    this.initSwiper();
    this.bindEvents();
    this.onSlideChange();
  }

  setVars(rootEl) {
    this.rootEl = rootEl;
    if (!this.rootEl) return;

    this.sliderEl = this.rootEl.querySelector('[data-authority-slider-swiper]');
    this.sliderImages = this.rootEl.querySelector('[data-authority-slider-images]');
    this.sliderThumbs = this.rootEl.querySelector('[data-authority-slider-thumbs]');

    this.btnPrev = this.rootEl.querySelector('[data-authority-slider-prev]');
    this.btnNext = this.rootEl.querySelector('[data-authority-slider-next]');

    return true;
  }

  bindEvents() {
    this.swiper.on('slideChange', this.onSlideChange.bind(this));
    this.imageSwiper.on('slideChange', this.onImageSlideChange.bind(this));
  }

  initSwiper() {
    this.thumbsSwiper = new Swiper(this.sliderThumbs, {
      slidesPerView: 1,
      spaceBetween: 40,
      speed: 1000,
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
      },
    });

    this.imageSwiper = new Swiper(this.sliderImages, {
      slidesPerView: 'auto',
      virtualTranslate: true,
    });

    this.swiper = new Swiper(this.sliderEl, {
      modules: [Navigation, Thumbs, Controller, EffectFade],
      slidesPerView: 1,
      spaceBetween: 40,
      effect: 'fade',
      speed: 400,
      navigation: {
        nextEl: this.btnNext,
        prevEl: this.btnPrev,
      },
      thumbs: {
        swiper: this.thumbsSwiper,
      },
      controller: {
        control: this.thumbsSwiper,
      },
    });

  }

  onImageSlideChange() {
    const { activeIndex } = this.imageSwiper;
    this.swiper.slideTo(activeIndex);
  }

  onSlideChange() {
    const { activeIndex, slides } = this.swiper;
    this.imageSwiper.slideTo(activeIndex);

    const { slides: imageSlides } = this.imageSwiper;

    const dataPrefix = 'data-authority-slider-';

    each(slides, (slideEl, slideIndex) => {
      setSlideAttrs(slideEl, slideIndex, activeIndex, dataPrefix);

      const imageSlideEl = imageSlides[slideIndex];
      setSlideAttrs(imageSlideEl, slideIndex, activeIndex, dataPrefix);
    });
  }

  destroy() {
    if (this.swiper) this.swiper.destroy();
  }
}

export default class AuthoritySlider {
  constructor() {
    this.entities = new Entities(
      'AuthoritySlider',
      '[data-authority-slider-swipers]',
      AuthoritySlider.initSingle,
      AuthoritySlider.destroySingle,
    );
  }

  static initSingle(element) {
    return new AuthoritySliderEntity(element);
  }

  static destroySingle({ entityObj }) {
    entityObj.destroy();
  }
}
