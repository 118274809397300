import { each } from '../helpers/array';
import { setClassName } from '../helpers/dom';
import { scrollToTarget } from './ScrollTo';
import Entities from './Entities';

class SideNavEntity {
  constructor(rootEl) {
    if (!this.setVars(rootEl)) return;

    this.bindEvents();
    this.runObserver();
  }

  setVars(rootEl) {
    this.rootEl = rootEl;
    if (!this.rootEl) return;

    this.navArr = this.rootEl.querySelectorAll('[data-side-nav-nav]');
    if (!this.navArr) return false;

    this.itemsArr = this.rootEl.querySelectorAll('[data-side-nav-item]');
    if (!this.itemsArr.length) return false;

    this.classes = {
      itemActive: 'active',
    };

    this.lastActiveIndex = null;

    return true;
  }

  bindEvents() {
    this.onButtonClickEvent = this.onButtonClick.bind(this);

    each(this.navArr, (buttonEl) => {
      buttonEl.addEventListener('click', this.onButtonClickEvent);
    });
  }

  onButtonClick(e) {
    e.preventDefault();
    const itemIndex = parseInt(e.currentTarget.getAttribute('data-side-nav-nav') || '-1');
    const itemEl = this.itemsArr[itemIndex];

    scrollToTarget(itemEl);
  }

  runObserver() {
    this.onObserveEvent = this.onObserve.bind(this);
    this.observer = new IntersectionObserver(this.onObserveEvent, {
      rootMargin: '-50%',
      threshold: 0,
    });

    each(this.itemsArr, (itemEl) => {
      this.observer.observe(itemEl);
    });
  }

  onObserve(entries) {
    each(entries, (entry) => {
      if (!entry.isIntersecting) return true;

      const activeIndex = entry.target.getAttribute('data-side-nav-item');
      if (activeIndex !== null) this.setActiveButton(parseInt(activeIndex));

    });
  }

  setActiveButton(activeIndex) {
    if (activeIndex === this.lastActiveIndex) return;

    each(this.navArr, (navEl, itemIndex) => {
      setClassName(navEl, this.classes.itemActive, itemIndex === activeIndex);
    });

    this.lastActiveIndex = activeIndex;
  }

}

export default class SideNav {
  constructor() {
    this.entities = new Entities(
      'SideNav',
      '[data-side-nav]',
      SideNav.initSingle,
      SideNav.destroySingle,
    );
  }

  static initSingle(element) {
    return new SideNavEntity(element);
  }

  static destroySingle({ entityObj }) {
    entityObj.destroy();
  }
}
