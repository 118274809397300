import anime from 'animejs/lib/anime.es';
import { each, mapEach } from '../helpers/array';
import { setClassName } from '../helpers/dom';
import { isRtl } from '../helpers/document';

export default class MobileMenu {

  constructor() {
    if (!this.setVars()) return;

    this.bindEvents();
  }

  setVars() {
    this.menuEl = document.querySelector('[data-mobile-menu]');
    if (!this.menuEl) return false;

    this.buttonsArr = document.querySelectorAll('[data-mobile-menu-button]');
    if (!this.buttonsArr) return false;

    this.closeButtonEl = this.menuEl.querySelector('[data-mobile-menu-close]');
    if (!this.closeButtonEl) return false;

    this.itemsEl = this.menuEl.querySelector('[data-mobile-menu-items]');
    if (!this.itemsEl) return false;

    this.itemsArr = this.itemsEl.querySelectorAll('[data-mobile-menu-item]');
    if (!this.itemsArr.length) return false;

    const linksArr = this.itemsEl.querySelectorAll('[data-mobile-menu-link]');
    if (!linksArr.length) return false;

    this.itemObjsArr = this.getItemObjsArr(linksArr);

    this.classes = {
      expanded: 'mobileMenu--expanded',
      // activeButton: 'menuButton--active',
      expandedItems: 'mobileMenu__items--expanded',
      expandedItem: 'mobileMenu__item--expanded',
      expandedLink: 'mobileMenu__link--expanded',
      expandedSubmenu: 'mobileMenu__submenu--expanded',
    };

    this.rtlSign = isRtl ? -1 : 1;

    this.isOpened = false;
    this.expandedItemId = null;

    this.duration = 400;

    return true;
  }

  bindEvents() {
    this.onButtonClickEvent = this.onButtonClick.bind(this);
    this.onCloseButtonClickEvent = this.onCloseButtonClick.bind(this);
    this.onLinkClickEvent = this.onLinkClick.bind(this);
    this.onBackClickEvent = this.onBackClick.bind(this);
    this.onClickOutsideEvent = this.onClickOutside.bind(this);

    each(this.buttonsArr, (buttonEl) => {
      buttonEl.addEventListener('click', this.onButtonClickEvent);
    });
    this.closeButtonEl.addEventListener('click', this.onCloseButtonClickEvent);

    each(this.itemObjsArr, ({ linkEl, backButtonEl }) => {
      if (linkEl === null) return true;

      linkEl.addEventListener('click', this.onLinkClickEvent);
      backButtonEl.addEventListener('click', this.onBackClickEvent);
    });
  }

  getItemObjsArr(linksArr) {
    return mapEach(linksArr, (linkEl) => {
      const id = linkEl.getAttribute('data-mobile-menu-link');
      const itemEl = this.itemsEl.querySelector(`[data-mobile-menu-item="${id}"]`);
      const submenuEl = this.itemsEl.querySelector(`[data-mobile-menu-submenu="${id}"]`);
      const backButtonEl = this.itemsEl.querySelector(`[data-mobile-menu-back="${id}"]`);

      return {
        id,
        itemEl,
        linkEl,
        submenuEl,
        backButtonEl,
      };
    });
  }

  onButtonClick(e) {
    e.preventDefault();
    this.showMenu();
  }

  onCloseButtonClick(e) {
    e.preventDefault();
    this.hideMenu();
  }

  onLinkClick(e) {
    e.preventDefault();
    const itemId = e.currentTarget.getAttribute('data-mobile-menu-link');
    this.setExpandedItem(itemId);
  }

  onBackClick() {
    this.setExpandedItem(null);
  }

  bindDocEvents() {
    document.addEventListener('click', this.onClickOutsideEvent);
  }

  unbindDocEvents() {
    document.removeEventListener('click', this.onClickOutsideEvent);
  }

  onClickOutside(e) {
    if (!this.isActive) return;

    if (
      e.target.closest('[data-mobile-menu]') === this.menuEl
      || e.target.closest('[data-mobile-menu-close]') === this.closeButtonEl
      || e.target.closest('[data-mobile-menu-button]') !== null
    ) return;

    this.hideMenu();
  }

  getItemObjById(itemId) {
    return this.itemObjsArr.find(itemObj => itemObj.id === itemId);
  }

  // toggleMenu()
  // {
  //   if (this.isActive) {
  //     this.hideMenu();
  //   } else {
  //     this.showMenu();
  //   }
  // }

  showMenu() {
    if (this.isActive) return;

    this.setClasses(true);
    this.bindDocEvents();

    this.isActive = true;
  }

  hideMenu() {
    if (!this.isActive) return;

    this.setClasses(false);
    this.unbindDocEvents();

    this.isActive = false;
  }

  setClasses(status) {
    setClassName(this.menuEl, this.classes.expanded, status);
    // each(this.buttonsArr, (buttonEl) => {
    //   setClassName(buttonEl, this.classes.activeButton, status);
    // });
  }

  // toggleItem(itemId)
  // {
  //   if (itemId === this.expandedItemId) {
  //     this.setExpandedItem(null);
  //     this.itemsEl.classList.remove(this.classes.expandedItems);
  //   } else {
  //     this.setExpandedItem(itemId);
  //     this.itemsEl.classList.add(this.classes.expandedItems);
  //   }
  // }

  setExpandedItem(itemId = null) {
    each(this.itemObjsArr, (itemObj) => {
      if (itemObj.id === itemId) {
        this.expandItem(itemObj);
      } else {
        this.collapseItem(itemObj);
      }
    });

    this.expandedItemId = itemId;
  }

  expandItem({ linkEl, itemEl, submenuEl }) {
    if (linkEl.classList.contains(this.classes.expandedLink)) return;

    itemEl.classList.add(this.classes.expandedItem);
    linkEl.classList.add(this.classes.expandedLink);
    linkEl.setAttribute('aria-expanded', true);

    anime.remove(submenuEl);
    anime.set(submenuEl, {
      translateX: `${this.rtlSign * 100}%`,
    });
    submenuEl.classList.add(this.classes.expandedSubmenu);
    anime({
      targets: submenuEl,
      translateX: '0%',
      opacity: 1,
      easing: 'easeOutCubic',
      duration: this.duration,
      complete: () => {
        submenuEl.style.height = '';
      },
    });
  }

  collapseItem({ linkEl, itemEl, submenuEl }) {
    if (!linkEl.classList.contains(this.classes.expandedLink)) return;

    itemEl.classList.remove(this.classes.expandedItem);
    linkEl.classList.remove(this.classes.expandedLink);
    linkEl.setAttribute('aria-expanded', false);

    anime.remove(submenuEl);
    anime.set(submenuEl, {
      translateX: '0%',
    });
    anime({
      targets: submenuEl,
      translateX: `${this.rtlSign * 100}%`,
      opacity: 0,
      easing: 'easeOutCubic',
      duration: this.duration,
      complete: () => {
        submenuEl.classList.remove(this.classes.expandedSubmenu);
        submenuEl.style.height = '';
      },
    });
  }
}
