import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import Entities from './Entities';

class AreasSliderEntity {
  constructor(rootEl) {
    if (!this.setVars(rootEl)) return;

    this.initSwiper();
  }

  setVars(rootEl) {
    this.rootEl = rootEl;
    if (!this.rootEl) return;

    this.sliderEl = this.rootEl.querySelector('[data-area-slider-swiper]');
    if (!this.sliderEl) return;

    this.nextButtonEl = this.rootEl.querySelector('[data-area-slider-next]');
    if (!this.nextButtonEl) return;

    this.prevButtonEl = this.rootEl.querySelector('[data-area-slider-prev]');
    if (!this.prevButtonEl) return;

    return true;
  }

  initSwiper() {
    this.swiper = new Swiper(this.sliderEl, {
      modules: [Navigation],
      slidesPerView: 1.6,
      spaceBetween: 20,
      speed: 1000,
      navigation: {
        nextEl: this.nextButtonEl,
        prevEl: this.prevButtonEl,
      },
      breakpoints: {
        768: {
          slidesPerView: 2.6,
        },
        1280: {
          slidesPerView: 3.2,
        },
      },
    });
  }

  destroy() {
    if (this.swiper) this.swiper.destroy();
  }
}

export default class AreasSlider {
  constructor() {
    this.entities = new Entities(
      'AreasSlider',
      '[data-area-slider]',
      AreasSlider.initSingle,
      AreasSlider.destroySingle,
    );
  }

  static initSingle(element) {
    return new AreasSliderEntity(element);
  }

  static destroySingle({ entityObj }) {
    entityObj.destroy();
  }
}
