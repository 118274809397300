import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import Entities from './Entities';
import { each } from '../helpers/array';
import { setSlideAttrs } from '../helpers/slider';

class GallerySliderEntity {
  constructor(rootEl) {
    if (!this.setVars(rootEl)) return;

    this.initSwiper();
    this.bindEvents();
    this.onSlideChange();
  }

  setVars(rootEl) {
    this.rootEl = rootEl;
    if (!this.rootEl) return;

    this.sliderEl = this.rootEl.querySelector('[data-gallery-slider-swiper]');
    if (!this.sliderEl) return;

    this.nextButtonEl = this.rootEl.querySelector('[data-gallery-slider-next]');
    if (!this.nextButtonEl) return;

    this.prevButtonEl = this.rootEl.querySelector('[data-gallery-slider-prev]');
    if (!this.prevButtonEl) return;

    return true;
  }

  initSwiper() {
    this.swiper = new Swiper(this.sliderEl, {
      modules: [Navigation],
      slidesPerView: 1.4,
      spaceBetween: 20,
      speed: 1000,
      loop: true,
      centeredSlides: true,
      // slideToClickedSlide: true,
      navigation: {
        nextEl: this.nextButtonEl,
        prevEl: this.prevButtonEl,
      },
      breakpoints: {
        768: {
          slidesPerView: 1.4,
        },
        1280: {
          slidesPerView: 1.4,
        },
      },
    });
  }

  bindEvents() {
    this.swiper.on('slideChange', this.onSlideChange.bind(this));
  }

  onSlideChange() {
    const { activeIndex, slides } = this.swiper;
    const dataPrefix = 'data-gallery-slider-';

    each(slides, (slideEl, slideIndex) => {
      setSlideAttrs(slideEl, slideIndex, activeIndex, dataPrefix);
    });
  }

  destroy() {
    if (this.swiper) this.swiper.destroy();
  }
}

export default class GallerySlider {
  constructor() {
    this.entities = new Entities(
      'GallerySlider',
      '[data-gallery-slider]',
      GallerySlider.initSingle,
      GallerySlider.destroySingle,
    );
  }

  static initSingle(element) {
    return new GallerySliderEntity(element);
  }

  static destroySingle({ entityObj }) {
    entityObj.destroy();
  }
}
