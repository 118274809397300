/* eslint-disable class-methods-use-this */
/* eslint-disable no-magic-numbers */
import anime from 'animejs/lib/anime.es';
import { each } from '../helpers/array';
import Entities from './Entities';

class IconsSectionEntity {
  constructor(rootEl) {
    if (!this.setVars(rootEl)) return;

    this.runObserver();
  }

  setVars(rootEl) {
    this.rootEl = rootEl;
    if (!this.rootEl) return;


    this.logo = this.rootEl.querySelector('[data-icons-section-logo]');
    if (!this.logo) return;

    this.items = this.rootEl.querySelectorAll('[data-icons-section-image]');
    if (!this.items) return;

    this.classes = {
      hideItem: 'iconsSection__item--hide',
      hideLogo: 'iconsSection__animation--hide',
    };

    return true;
  }

  showImage(imageObj, offset, index) {

    anime.remove(imageObj);

    if (index < 1) {
      anime.set(imageObj, {
        position: 'absolute',
        translateX: '-50%',
        left: '50%',
        opacity: 0,
      });

      anime({
        targets: imageObj,
        translateX: 0,
        left: `${(offset)}px`,
        opacity: 1,
        easing: 'linear',
        duration: 600,
      });
    }
    else {
      anime.set(imageObj, {
        position: 'absolute',
        translateX: '-50%',
        right: '50%',
        opacity: 0,
      });

      anime({
        targets: imageObj,
        translateX: 0,
        right: `${(offset)}px`,
        opacity: 1,
        easing: 'linear',
        duration: 600,
      });
    }
  }

  runObserver() {
    this.onObserveEvent = this.onObserve.bind(this);
    this.observer = new IntersectionObserver(this.onObserveEvent, {
      // rootMargin: '0',
      threshold: 1,
    });

    if (window.innerWidth >= 768) {
      each(this.items, (itemEl) => {
        this.observer.observe(itemEl);
        itemEl.classList.add(this.classes.hideItem);
      });

      this.observer.observe(this.logo);
    }
  }

  onObserve(entries) {
    each(entries, (entry) => {
      if (!entry.isIntersecting) return true;
      if (entry.target === this.logo) {
        entry.target.classList.add(this.classes.hideLogo);
      }
      else {
        entry.target.classList.remove(this.classes.hideItem);
      }
    });
  }

}

export default class IconsSection {
  constructor() {
    this.entities = new Entities(
      'IconsSection',
      '[data-icons-section]',
      IconsSection.initSingle,
      IconsSection.destroySingle,
    );
  }

  static initSingle(element) {
    return new IconsSectionEntity(element);
  }

  static destroySingle({ entityObj }) {
    entityObj.destroy();
  }
}
