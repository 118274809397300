import Entities from './Entities';

class ToastEntity {
  constructor(rootEl) {
    if (!this.setVars(rootEl)) return;

    this.bindEvents();
  }

  setVars(rootEl) {
    this.rootEl = rootEl;
    if (!this.rootEl) return;

    this.closeEl = this.rootEl.querySelector('[data-toast-close]');
    if (!this.closeEl) return;

    this.classes = {
      errorActive: 'input__error--active',
      messageActive: 'toast--active',
    };

    return true;
  }

  bindEvents() {
    this.closeEl.addEventListener('click', this.onClose.bind(this));
  }

  onClose() {
    this.rootEl.classList.remove(this.classes.messageActive);
  }

  destroy() {
    if (this.swiper) this.swiper.destroy();
  }
}

export default class Toast {
  constructor() {
    this.entities = new Entities(
      'Toast',
      '[data-toast]',
      Toast.initSingle,
      Toast.destroySingle,
    );
  }

  static initSingle(element) {
    return new ToastEntity(element);
  }

  static destroySingle({ entityObj }) {
    entityObj.destroy();
  }
}
