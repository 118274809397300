import Swiper from 'swiper';
import { each } from '../helpers/array';
import { isRtl } from '../helpers/document';

import Entities from './Entities';
// import { clamp } from '../helpers/number';

class LeadershipHeaderEntity {

  constructor(rootEl) {
    if (!this.setVars(rootEl)) return;

    this.initSwiper();
    this.initScroll();
  }

  setVars(rootEl) {
    this.rootEl = rootEl;
    if (!this.rootEl) return false;

    this.sliderEl = this.rootEl.querySelector('[data-leadership-header-scroll-slider]');
    if (!this.sliderEl) return false;

    this.itemsEl = this.sliderEl.querySelector('[data-leadership-header-scroll-items]');
    if (!this.itemsEl) return false;

    this.mode = this.sliderEl.getAttribute('data-leadership-header-scroll-slider') || 'drag';

    this.rtlSign = isRtl ? -1 : 1;

    return true;
  }

  initSwiper() {
    if (this.mode !== 'drag') return;

    this.swiper = new Swiper(this.sliderEl, {
      slidesPerView: 'auto',
      loop: false,
      freeMode: true,
    });
  }

  initScroll() {
    if (this.mode !== 'scroll') return;

    this.onScrollEvent = this.onScroll.bind(this);
    // window.addEventListener('liteScroll', this.onScrollEvent);

    this.onObserveEvent = this.onObserve.bind(this);
    this.observer = new IntersectionObserver(this.onObserveEvent, {
      threshold: 0,
    });
    this.observer.observe(this.rootEl);

    this.onScroll();
  }

  onObserve(entries) {
    each(entries, (entry) => {
      if (entry.target !== this.rootEl) return true;

      if (entry.isIntersecting) {
        // console.log('bind event');
        window.addEventListener('liteScroll', this.onScrollEvent);
      } else {
        // console.log('unbind event');
        window.removeEventListener('liteScroll', this.onScrollEvent);
      }
    });
  }

  onScroll() {
    const winHeight = window.innerHeight;
    const rootRect = this.rootEl.getBoundingClientRect();
    const rootRange = rootRect.height - winHeight;
    const progress = (1 - (rootRange + rootRect.top) / rootRange);

    const sliderRange = this.itemsEl.scrollWidth - this.itemsEl.offsetWidth;
    const scroll = sliderRange * progress;

    this.itemsEl.style.transform = `translate3d(${-scroll * this.rtlSign}px, 0px, 0px)`;
  }
}

export default class LeadershipHeader {
  constructor() {
    this.entities = new Entities(
      'LeadershipHeader',
      '[data-leadership-header-scroll]',
      LeadershipHeader.initSingle,
    );
  }

  static initSingle(element) {
    return new LeadershipHeaderEntity(element);
  }
}
