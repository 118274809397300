import Swiper from 'swiper';
import { Navigation, Thumbs } from 'swiper/modules';
import Entities from './Entities';

class HistorySectionEntity {
  constructor(rootEl) {
    if (!this.setVars(rootEl)) return;

    this.initSwiper();
  }

  setVars(rootEl) {
    this.rootEl = rootEl;
    if (!this.rootEl) return;

    this.mainSlider = this.rootEl.querySelector('[data-history-swiper]');
    if (!this.mainSlider) return;
    console.log(this.rootEl.querySelector('[data-history-swiper]'));

    this.yearSlider = this.rootEl.querySelector('[data-history-years]');
    if (!this.yearSlider) return;

    this.nextButtonEl = this.rootEl.querySelector('[data-history-next]');
    if (!this.nextButtonEl) return;

    this.prevButtonEl = this.rootEl.querySelector('[data-history-prev]');
    if (!this.prevButtonEl) return;

    return true;
  }

  initSwiper() {
    this.years = new Swiper(this.yearSlider, {
      slidesPerView: 3,
      spaceBetween: 50,
      speed: 1000,
      breakpoints: {
        768: {
          slidesPerView: 5,
        },
        1280: {
          slidesPerView: 7,
        },
      },
    });

    this.swiper = new Swiper(this.mainSlider, {
      modules: [Navigation, Thumbs],
      slidesPerView: 1,
      spaceBetween: 20,
      speed: 1000,
      navigation: {
        nextEl: this.nextButtonEl,
        prevEl: this.prevButtonEl,
      },
      breakpoints: {
        768: {
          spaceBetween: 80,
        },
        1280: {
          spaceBetween: 100,
        },
      },
      thumbs: {
        swiper: this.years,
      },
    });
  }

  destroy() {
    if (this.swiper) this.swiper.destroy();
  }
}

export default class HistorySection {
  constructor() {
    this.entities = new Entities(
      'HistorySection',
      '[data-history]',
      HistorySection.initSingle,
      HistorySection.destroySingle,
    );
  }

  static initSingle(element) {
    return new HistorySectionEntity(element);
  }

  static destroySingle({ entityObj }) {
    entityObj.destroy();
  }
}
