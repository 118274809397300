import AOS from 'aos';
import PotatoScroll from 'potatoscroll';
import { trigger } from '../helpers/event';

import LiteEvents from './LiteEvents';
import LockScroll from './LockScroll';
import ScrollTo from './ScrollTo';
import Header from './Header';
import MobileMenu from './MobileMenu';
import Toggle from './Toggle';
import InputLabel from './InputLabel';
import CustomSelect from './CustomSelect';
import Popup from './Popup';
import HeroSlider from './HeroSlider';
import TextPhotosSlider from './TextPhotosSlider';
import VideoPoster from './VideoPoster';
import VideoPopup from './VideoPopup';
import AreasSlider from './AreasSlider';
import PostsSlider from './PostsSlider';
import CardsSlider from './CardsSlider';
import Accordion from './Accordion';
import PeopleSlider from './PeopleSlider';
import VideoPlayer from './VideoPlayer';
import FilesDownload from './FilesDownload';
import GoogleMap from './GoogleMap';
import SideNav from './SideNav';
// import Breadcrumbs from './Breadcrumbs';
import IconsSection from './IconsSection';
import FaqSection from './FaqSection';
import HistorySection from './HistorySection';
import InitiativesSection from './InitiativesSection';
import BeforeAfter from './BeforeAfter';
import RequiredFields from './RequiredFields';
import TestimonialsSlider from './TestimonialsSlider';
import HorizontalItemsScroll from './HorizontalItemsScroll';
import LogosSlider from './LogosSlider';
import AreasDevelopment from './AreasDevelopment';
import GalleryExpandable from './GalleryExpandable';
import GallerySlider from './GallerySlider';
import ClearSelect from './ClearSelect';
import AuthorityTabs from './AuthorityTabs';
import AuthoritySlider from './AuthoritySlider';
import Toast from './Toast';
import LeadershipHeader from './LeadershipHeader';

export default class Core {
  constructor() {
    window.triggerEvent = trigger;

    new LiteEvents();
    new LockScroll();
    new ScrollTo();
    new Header();
    new MobileMenu();
    new Toggle();
    new InputLabel();
    new CustomSelect();
    new Popup();
    new HeroSlider();
    new TextPhotosSlider();
    new VideoPoster();
    new VideoPopup();
    new AreasSlider();
    new PostsSlider();
    new CardsSlider();
    new Accordion();
    new PeopleSlider();
    new VideoPlayer();
    new FilesDownload();
    new GoogleMap();
    new SideNav();
    // new Breadcrumbs();
    new IconsSection();
    new FaqSection();
    new HistorySection();
    new InitiativesSection();
    new BeforeAfter();
    new RequiredFields();
    new TestimonialsSlider();
    new HorizontalItemsScroll();
    new LogosSlider();
    new AreasDevelopment();
    new GalleryExpandable();
    new GallerySlider();
    new ClearSelect();
    new AuthorityTabs();
    new AuthoritySlider();
    new Toast();
    new LeadershipHeader();

    PotatoScroll.create({ selector: '.potatoScroll' });

    // AOS
    AOS.init({
      offset: 100,
      duration: 1000,
    });
  }
}
