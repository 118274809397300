/* eslint-disable class-methods-use-this */
/* eslint-disable no-magic-numbers */
import anime from 'animejs/lib/anime.es';
import { each } from '../helpers/array';
import Entities from './Entities';

class AccordionEntity {
  constructor(rootEl) {
    if (!this.setVars(rootEl)) return;

    this.bindEvents();
  }

  setVars(rootEl) {
    this.rootEl = rootEl;
    if (!this.rootEl) return;

    this.items = this.rootEl.querySelectorAll('[data-accordion-item]');
    if (!this.items) return;

    this.images = this.rootEl.querySelectorAll('[data-accordion-image]');
    if (!this.items) return;

    this.info = this.rootEl.querySelector('[data-accordion-infos]');
    this.content = this.rootEl.querySelector('[data-accordion-content]');
    this.image = this.rootEl.querySelector('[data-accordion-images]');

    this.classes = {
      activeIcon: 'accordionSection__plus--active',
    };

    this.active = false;
    this.activeItem = null;

    return true;
  }

  bindEvents() {
    each(this.items, (item) => {
      item.addEventListener('click', this.onButtonClick.bind(this));
    });

    window.addEventListener('load', this.setSize.bind(this));
  }

  setSize() {
    if (this.image) {
      const width = window.innerWidth;
      const breakpoint = 768;
      if (width > breakpoint) {
        setTimeout(() => {
          const height = this.content.offsetHeight;
          this.image.style.height = `${height}px`;
        }, 300);
      }
    }
  }

  onButtonClick(e) {
    e.preventDefault();
    const itemIndex = parseInt(e.currentTarget.getAttribute('data-accordion-item') || '-1');

    each(this.items, (item, index) => {
      const body = item.querySelector('[data-accordion-body]');
      const icon = item.querySelector('.accordionSection__plus');

      if (itemIndex === index && this.activeItem !== itemIndex) {
        this.showItem(body);
        icon.classList.add(this.classes.activeIcon);
      }
      else {
        this.hideItem(body);
        icon.classList.remove(this.classes.activeIcon);
      }
    });

    this.active = true;

    each(this.images, (image, index) => {
      if (itemIndex === index && this.activeItem !== itemIndex && this.active === true) {
        this.showImage(image, index);
      }
    });

    if (this.activeItem === itemIndex) {
      this.activeItem = null;
      this.showInfo();
    }
    else {
      this.activeItem = itemIndex;
      this.hideInfo();
    }

  }

  showItem(itemObj) {

    anime.remove(itemObj);
    anime.set(itemObj, {
      height: 0,
      opacity: 0,
      marginTop: 0,
    });

    anime({
      targets: itemObj,
      height: itemObj.scrollHeight,
      marginTop: '12px',
      opacity: 1,
      easing: 'easeInOutCubic',
      duration: 600,
    });
  }

  hideItem(itemObj) {

    anime.remove(itemObj);
    anime({
      targets: itemObj,
      height: 0,
      marginTop: 0,
      opacity: 0,
      easing: 'easeInOutCubic',
      duration: 600,
    });
  }

  showImage(imageObj, index) {

    const translate = index > this.activeItem ? 100 : -100;

    anime.remove(imageObj);
    anime.set(imageObj, {
      translateY: `${(translate)}%`,
      zIndex: 3,
    });

    anime({
      targets: imageObj,
      translateY: 0,
      zIndex: 3,
      easing: 'linear',
      duration: 600,
      complete: () => {
        this.resetImages(imageObj);
      },
      changeComplete: () => {
        imageObj.style.zIndex = 2;
      },
    });
  }

  resetImages(imageObj) {
    each(this.images, (image) => {
      if (imageObj === image) return;
      image.style.zIndex = 1;
    });
  }

  showInfo() {
    if (!this.info) return;

    anime.remove(this.info);
    anime.set(this.info, {
      height: 0,
      opacity: 0,
    });

    anime({
      targets: this.info,
      height: this.info.scrollHeight,
      opacity: 1,
      easing: 'easeInOutCubic',
      duration: 600,
    });
  }

  hideInfo() {
    if (!this.info) return;

    anime.remove(this.info);
    anime({
      targets: this.info,
      height: 0,
      opacity: 0,
      easing: 'easeInOutCubic',
      duration: 600,
    });
  }

}

export default class Accordion {
  constructor() {
    this.entities = new Entities(
      'Accordion',
      '[data-accordion]',
      Accordion.initSingle,
      Accordion.destroySingle,
    );
  }

  static initSingle(element) {
    return new AccordionEntity(element);
  }

  static destroySingle({ entityObj }) {
    entityObj.destroy();
  }
}
