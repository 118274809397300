/* eslint-disable max-len */
/* eslint-disable arrow-body-style */
import anime from 'animejs/lib/anime.es';
import { each } from '../helpers/array';
import Entities from './Entities';

class AuthorityTabsEntity {
  constructor(rootEl) {
    if (!this.setVars(rootEl)) return;

    this.bindEvents();
  }

  setVars(rootEl) {
    this.rootEl = rootEl;
    if (!this.rootEl) return;

    this.buttonsArr = this.rootEl.querySelectorAll('.authoritySlider__navItem');
    if (!this.buttonsArr) return false;

    this.subButtonsArr = this.rootEl.querySelectorAll('.authoritySlider__subNavItem');

    this.contentWrapEl = this.rootEl.querySelector('.authoritySlider__items');
    if (!this.contentWrapEl) return false;

    this.contentsArr = this.contentWrapEl.querySelectorAll('.authoritySlider__item');
    if (!this.contentsArr) return false;

    this.subContentsArr = this.contentWrapEl.querySelectorAll('.authoritySlider__subItem');

    this.classes = {
      buttonActive: 'authoritySlider__navItem--active',
      contentActive: 'authoritySlider__item--active',
      contentPrev: 'authoritySlider__item--prev',
      subButtonActive: 'authoritySlider__subNavItem--active',
      subContentActive: 'authoritySlider__subItem--active',
      subContentPrev: 'authoritySlider__subItem--prev',
    };

    this.prevContentEl = Array.prototype.filter.call(this.contentsArr, (content) => {
      return content.classList.contains(this.classes.contentActive);
    })[0] || this.contentsArr[0];

    return true;
  }

  bindEvents() {
    this.onButtonClick = this.onButtonClick.bind(this);
    each(this.buttonsArr, (buttonEl) => {
      buttonEl.addEventListener('click', this.onButtonClick);
    });

    if (this.subButtonsArr) {
      this.onSubButtonClick = this.onSubButtonClick.bind(this);
      each(this.subButtonsArr, (buttonEl) => {
        buttonEl.addEventListener('click', this.onSubButtonClick);
      });
    }
  }

  onButtonClick(e) {
    e.preventDefault();
    const buttonEl = e.currentTarget;
    const tabId = buttonEl.getAttribute('data-authority-slider-tab');

    const contentEl = Array.prototype.filter.call(this.contentsArr, (content) => {
      return content.getAttribute('data-authority-slider-tab') === tabId;
    })[0];

    // change button
    this.unactivateAllButtons();
    buttonEl.classList.add(this.classes.buttonActive);

    // change content
    this.goToTab(contentEl);
  }

  onSubButtonClick(e) {
    e.preventDefault();
    const buttonEl = e.currentTarget;
    const tabId = buttonEl.getAttribute('data-authority-slider-sub-tab');

    const contentEl = Array.prototype.filter.call(this.subContentsArr, (content) => {
      return content.getAttribute('data-authority-slider-sub-tab') === tabId;
    })[0];

    // change button
    this.unactivateAllButtons(true);
    buttonEl.classList.add(this.classes.subButtonActive);

    // change content
    this.goToSubTab(contentEl);
    console.log(tabId);
  }

  unactivateAllButtons(subNav = false) {
    if (subNav) {
      const subWrapper = this.rootEl.querySelector('.authoritySlider__item--active .authoritySlider__subNav');
      this.subItemsButtons = subWrapper.querySelectorAll('.authoritySlider__subNavItem');
      Array.prototype.forEach.call(this.subItemsButtons, (buttonEl) => {
        buttonEl.classList.remove(this.classes.subButtonActive);
      });
    } else {
      Array.prototype.forEach.call(this.buttonsArr, (buttonEl) => {
        buttonEl.classList.remove(this.classes.buttonActive);
      });
    }
  }

  goToTab(contentEl) {
    if (contentEl.classList.contains(this.classes.contentActive)) return;

    const { prevContentEl } = this;
    this.prevContentEl = contentEl;

    anime.remove(this.contentWrapEl);
    anime.remove(prevContentEl);
    anime.remove(contentEl);

    anime.set(this.contentWrapEl, { height: `${prevContentEl.scrollHeight}px` });
    anime.set(prevContentEl, { opacity: 1 });
    anime.set(contentEl, { opacity: 0 });

    prevContentEl.classList.add(this.classes.contentPrev);
    prevContentEl.classList.remove(this.classes.contentActive);
    contentEl.classList.add(this.classes.contentActive);

    anime({
      targets: this.contentWrapEl,
      height: `${contentEl.scrollHeight}px`,
      duration: 400,
      easing: 'easeOutCubic',
      complete: () => {
        this.contentWrapEl.style.height = '';
      },
    });

    anime({
      targets: prevContentEl,
      opacity: 0,
      duration: 400,
      easing: 'easeOutCubic',
      complete: () => {
        prevContentEl.classList.remove(this.classes.contentPrev);
        prevContentEl.style.opacity = '';
      },
    });

    anime({
      targets: contentEl,
      opacity: 1,
      duration: 400,
      easing: 'easeOutCubic',
      complete: () => {
        contentEl.style.opacity = '';
      },
    });
  }

  goToSubTab(contentEl) {
    if (contentEl.classList.contains(this.classes.subContentActive)) return;

    const subWrapper = this.rootEl.querySelector('.authoritySlider__item--active .authoritySlider__subItems');
    this.subItemsArr = subWrapper.querySelectorAll('.authoritySlider__subItem');
    const prevSubContentEl = Array.prototype.filter.call(this.subItemsArr, (subContent) => {
      return subContent.classList.contains(this.classes.subContentActive);
    })[0] || this.subContentsArr[0];
    this.prevSubContentEl = contentEl;

    anime.remove(this.contentWrapEl);
    anime.remove(prevSubContentEl);
    anime.remove(contentEl);

    anime.set(this.contentWrapEl, { height: `${prevSubContentEl.scrollHeight}px` });
    anime.set(prevSubContentEl, { opacity: 1 });
    anime.set(contentEl, { opacity: 0 });

    console.log(prevSubContentEl, this.subItemsArr);
    if (prevSubContentEl) {
      prevSubContentEl.classList.add(this.classes.subContentPrev);
      prevSubContentEl.classList.remove(this.classes.subContentActive);
    } else {
      each(this.subContentsArr, (prevContentEl) => {
        console.log(prevContentEl);
        prevContentEl.classList.remove(this.classes.subContentActive);
      });
    }
    contentEl.classList.add(this.classes.subContentActive);

    anime({
      targets: this.contentWrapEl,
      height: `${contentEl.scrollHeight}px`,
      duration: 400,
      easing: 'easeOutCubic',
      complete: () => {
        this.contentWrapEl.style.height = '';
      },
    });

    anime({
      targets: prevSubContentEl,
      opacity: 0,
      duration: 400,
      easing: 'easeOutCubic',
      complete: () => {
        prevSubContentEl.classList.remove(this.classes.subContentPrev);
        prevSubContentEl.style.opacity = '';
      },
    });

    anime({
      targets: contentEl,
      opacity: 1,
      duration: 400,
      easing: 'easeOutCubic',
      complete: () => {
        contentEl.style.opacity = '';
      },
    });
  }

  onImageSlideChange() {
    const { activeIndex } = this.imageSwiper;
    this.swiper.slideTo(activeIndex);
  }

  destroy() {
    if (this.swiper) this.swiper.destroy();
  }
}

export default class AuthorityTabs {
  constructor() {
    this.entities = new Entities(
      'AuthorityTabs',
      '[data-authority-slider]',
      AuthorityTabs.initSingle,
      AuthorityTabs.destroySingle,
    );
  }

  static initSingle(element) {
    return new AuthorityTabsEntity(element);
  }

  static destroySingle({ entityObj }) {
    entityObj.destroy();
  }
}
