import { each } from '../helpers/array';

export default class VideoPlayer {
  constructor() {
    if (!this.setVars()) return;

    this.runObserver();
  }

  setVars() {
    this.videoArr = document.querySelectorAll('[data-video-looped]');
    if (!this.videoArr) return false;

    return true;
  }

  playVideo(target) {
    target.play();
  }

  pauseVideo(target) {
    target.pause();
  }

  runObserver() {
    this.onObserveEvent = this.onObserve.bind(this);
    this.observer = new IntersectionObserver(this.onObserveEvent, {
      threshold: 0,
    });

    each(this.videoArr, (item) => {
      this.observer.observe(item);
    });
  }

  onObserve(entries) {
    each(entries, (entry) => {
      if (entry.isIntersecting) {
        entry.target.play();
      }
      else {
        entry.target.pause();
      }
    });
  }
}
