import Entities from './Entities';
import { each, mapEach } from '../helpers/array';
import { setClassName } from '../helpers/dom';
import { trigger } from '../helpers/event';

const VIDEO_PLAYING_CLASS = 'video--playing';
const REMOVE_VIDEO_DELAY = 200;

export default class VideoPoster {
  constructor() {
    this.entities = new Entities(
      'VideoPoster',
      '[data-video-poster]',
      VideoPoster.initSingle,
      VideoPoster.destroySingle,
    );
  }

  static initSingle(containerEl) {
    const playEl = containerEl.querySelector('[data-video-poster-play]');
    if (playEl === null) return null;

    const stopEl = containerEl.querySelector('[data-video-poster-stop]');

    const videoEl = containerEl.querySelector('[data-video-poster-video]');
    // const iframeEl = containerEl.querySelector('[data-video-poster-iframe]');

    let playing = false;

    const srcArr = containerEl.querySelectorAll('[data-video-poster-src]');
    const srcObjsArr = mapEach(srcArr, (el) => {
      const parentEl = el.parentElement;
      const src = el.getAttribute('data-video-poster-src');

      parentEl.removeChild(el);
      el.src = src;

      return {
        el,
        src,
        parentEl,
      };
    });

    function start() {
      if (playing) return;

      each(srcObjsArr, ({ el, parentEl }) => {
        parentEl.appendChild(el);
      });

      setClassName(containerEl, VIDEO_PLAYING_CLASS, true);
      trigger(containerEl, 'VideoPosterStarted');

      if (videoEl !== null) {
        videoEl.play();
      }

      playing = true;
    }

    function stop() {
      if (!playing) return;

      setClassName(containerEl, VIDEO_PLAYING_CLASS, false);
      trigger(containerEl, 'VideoPosterEnded');

      setTimeout(() => {
        playing = false;

        if (videoEl !== null) {
          videoEl.pause();
          videoEl.currentTime = 0;
        }

        each(srcObjsArr, ({ el, parentEl }) => {
          parentEl.removeChild(el);
        });
      }, REMOVE_VIDEO_DELAY);
    }

    function onPlay() {
      start();
    }

    function onPlayClick() {
      start();
    }

    function onStop() {
      stop();
    }

    function onStopClick() {
      stop();
    }

    function onEnded() {
      stop();
    }

    function onOutOfView() {
      if (!playing) return;

      const pos = containerEl.getBoundingClientRect();
      const height = containerEl.scrollHeight;
      const bounds = 0.7;

      if (pos.y * -1 > height * bounds) stop();
    }

    // stop playing video when video is almost out of view
    document.addEventListener('scroll', onOutOfView);

    containerEl.addEventListener('VideoPosterPlay', onPlay);
    containerEl.addEventListener('VideoPosterStop', onStop);

    playEl.addEventListener('click', onPlayClick);
    stopEl.addEventListener('click', onStopClick);
    if (videoEl !== null) videoEl.addEventListener('ended', onEnded);

    function destroy() {
      playEl.removeEventListener('click', onPlayClick);
      stopEl.removeEventListener('click', onStopClick);
      if (videoEl !== null) videoEl.removeEventListener('ended', onEnded);
    }

    return {
      destroy,
    };
  }

  static destroySingle({ entityObj }) {
    entityObj?.destroy();
  }
}
