import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import Entities from './Entities';

class PeopleSliderEntity {
  constructor(rootEl) {
    if (!this.setVars(rootEl)) return;

    this.initSwiper();
  }

  setVars(rootEl) {
    this.rootEl = rootEl;
    if (!this.rootEl) return;

    this.sliderEl = this.rootEl.querySelector('[data-people-slider-swiper]');
    if (!this.sliderEl) return;

    this.itemsArr = this.rootEl.querySelectorAll('[data-people-slider-items]');
    if (!this.itemsArr) return;

    this.nextButtonEl = this.rootEl.querySelector('[data-people-slider-next]');
    this.prevButtonEl = this.rootEl.querySelector('[data-people-slider-prev]');

    return true;
  }

  initSwiper() {

    this.swiper = new Swiper(this.sliderEl, {
      modules: [Navigation],
      slidesPerView: 1.2,
      spaceBetween: 0,
      speed: 1000,
      initialSlide: 1,
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: this.nextButtonEl,
        prevEl: this.prevButtonEl,
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        1280: {
          slidesPerView: 3,
        },
      },
    });
  }

  destroy() {
    if (this.swiper) this.swiper.destroy();
  }
}

export default class PeopleSlider {
  constructor() {
    this.entities = new Entities(
      'PeopleSlider',
      '[data-people-slider]',
      PeopleSlider.initSingle,
      PeopleSlider.destroySingle,
    );
  }

  static initSingle(element) {
    return new PeopleSliderEntity(element);
  }

  static destroySingle({ entityObj }) {
    entityObj.destroy();
  }
}
