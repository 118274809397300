/* eslint-disable no-magic-numbers */
import Swiper from 'swiper';
import { Navigation, Autoplay } from 'swiper/modules';
import Entities from './Entities';

class LogosSliderEntity {
  constructor(rootEl) {
    if (!this.setVars(rootEl)) return;

    this.initSwiper();
  }

  setVars(rootEl) {
    this.rootEl = rootEl;
    if (!this.rootEl) return;

    this.sliderEl = this.rootEl.querySelector('[data-logos-slider-swiper]');
    if (!this.sliderEl) return;

    this.itemsArr = this.rootEl.querySelectorAll('[data-logos-slider-item]');
    if (!this.itemsArr) return;

    this.nextButtonEl = this.rootEl.querySelector('[data-logos-slider-next]');
    this.prevButtonEl = this.rootEl.querySelector('[data-logos-slider-prev]');

    if (this.rootEl.classList.contains('logosSlider--squares')) {
      this.smallerSpacing = true;
    } else {
      this.smallerSpacing = false;
    }

    return true;
  }

  initSwiper() {
    this.swiper = new Swiper(this.sliderEl, {
      modules: [Navigation, Autoplay],
      slidesPerView: 'auto',
      spaceBetween: this.smallerSpacing ? 12 : 40,
      speed: 2400,
      loop: true,
      navigation: {
        nextEl: this.nextButtonEl,
        prevEl: this.prevButtonEl,
      },
      autoplay: {
        delay: 500,
      },
      breakpoints: {
        768: {
          // slidesPerView: 4,
          spaceBetween: this.smallerSpacing ? 14 : 60,
        },
        1280: {
          // slidesPerView: 6,
          spaceBetween: this.smallerSpacing ? 16 : 90,
        },
      },
    });
  }

  destroy() {
    if (this.swiper) this.swiper.destroy();
  }
}

export default class LogosSlider {
  constructor() {
    this.entities = new Entities(
      'LogosSlider',
      '[data-logos-slider]',
      LogosSlider.initSingle,
      LogosSlider.destroySingle,
    );
  }

  static initSingle(element) {
    return new LogosSliderEntity(element);
  }

  static destroySingle({ entityObj }) {
    entityObj.destroy();
  }
}
