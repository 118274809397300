import { each } from '../helpers/array';
import { isRtl } from '../helpers/document';
import Entities from './Entities';

class HorizontalItemsScrollEntity {
  constructor(rootEl) {
    if (!this.setVars(rootEl)) return;

    this.bindEvents();
    this.runObserver();
  }

  setVars(rootEl) {
    this.rootEl = rootEl;
    if (!this.rootEl) return;

    this.sliderEl = this.rootEl.querySelector('[data-horizontal-items-slider]');
    if (!this.sliderEl) return false;

    this.itemsEl = this.rootEl.querySelectorAll('[data-horizontal-items-item]');
    if (!this.itemsEl) return false;

    this.size = this.itemsEl.length - 1;

    this.rtlSign = isRtl ? -1 : 1;

    return true;
  }

  bindEvents() {
    this.onScrollEvent = this.onScroll.bind(this);
  }

  runObserver() {
    this.onObserveEvent = this.onObserve.bind(this);
    this.observer = new IntersectionObserver(this.onObserveEvent, {
      threshold: 0,
    });

    this.observer.observe(this.rootEl);
  }

  onObserve(entries) {
    each(entries, (entry) => {
      if (entry.isIntersecting) {
        window.addEventListener('liteScroll', this.onScrollEvent);
      } else {
        window.removeEventListener('liteScroll', this.onScrollEvent);
      }
    });
  }

  onScroll() {
    const winHeight = window.innerHeight;
    const rootRect = this.rootEl.getBoundingClientRect();
    const rootRange = (rootRect.height - winHeight) * this.size;
    const progress = (1 - (rootRange + rootRect.top) / rootRange);

    const sliderRange = (this.sliderEl.scrollWidth - this.sliderEl.offsetWidth) * 0.4;
    const scroll = sliderRange * progress;

    this.sliderEl.style.transform = `translate3d(${-scroll * this.rtlSign}px, 0px, 0px)`;
  }

  destroy() {
    this.observer.unobserve(this.rootEl);
  }
}

export default class HorizontalItemsScroll {
  constructor() {
    this.entities = new Entities(
      'HorizontalItemsScroll',
      '[data-horizontal-items]',
      HorizontalItemsScroll.initSingle,
      HorizontalItemsScroll.destroySingle,
    );
  }

  static initSingle(element) {
    return new HorizontalItemsScrollEntity(element);
  }

  static destroySingle({ entityObj }) {
    entityObj.destroy();
  }
}
