import Entities from './Entities';
import { each } from '../helpers/array';

class ClearSelectEntity {
  constructor(rootEl) {
    if (!this.setVars(rootEl)) return;

    this.bindEvents();
  }

  setVars(rootEl) {
    this.rootEl = rootEl;
    if (!this.rootEl) return;

    this.id = this.rootEl.getAttribute('data-tags-clear-all');
    if (!this.id) return;

    this.selectEl = document.querySelector(`#${this.id}`);
    if (!this.selectEl) return;

    return true;
  }

  bindEvents() {
    this.rootEl.addEventListener('click', this.onClick.bind(this));
  }

  // eslint-disable-next-line class-methods-use-this
  onClick() {
    const { options } = this.selectEl;
    each(options, (option) => {
      option.selected = false;
    });
    const updateEvent = new CustomEvent('CustomSelectUpdate');
    window.dispatchEvent(updateEvent);
  }

  destroy() {
    if (this.swiper) this.swiper.destroy();
  }
}

export default class ClearSelect {
  constructor() {
    this.entities = new Entities(
      'ClearSelect',
      '[data-tags-clear-all]',
      ClearSelect.initSingle,
      ClearSelect.destroySingle,
    );
  }

  static initSingle(element) {
    return new ClearSelectEntity(element);
  }

  static destroySingle({ entityObj }) {
    entityObj.destroy();
  }
}
