export default class Header {
  constructor() {
    if (!this.setVars()) return;

    this.bindEvents();
  }

  setVars() {
    this.headerEl = document.querySelector('[data-header]');
    if (!this.headerEl) return false;

    this.classes = {
      notTop: 'header--notTop',
      scrollDown: 'header--scrollDown',
    };

    this.lastScrollTop = 0;

    return true;
  }

  bindEvents() {
    this.onScrollEvent = this.onScroll.bind(this);
    window.addEventListener('liteScroll', this.onScrollEvent);
  }

  onScroll(e) {
    const { scrollTop } = e.detail;

    const isNotTop = scrollTop > this.headerEl.offsetHeight;
    const isScrollDown = scrollTop > this.lastScrollTop;

    Header.setClassName(this.headerEl, this.classes.notTop, isNotTop);
    Header.setClassName(this.headerEl, this.classes.scrollDown, isScrollDown);

    this.lastScrollTop = scrollTop;
  }

  static setClassName(el, className, status) {
    if (status) {
      el.classList.add(className);
    } else {
      el.classList.remove(className);
    }
  }
}
