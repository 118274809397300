/* eslint-disable no-magic-numbers */
import { Loader } from 'google-maps';

export default class GoogleMap {
  constructor() {
    if (!this.setVars()) return false;
  }

  setVars() {
    this.maps = document.querySelectorAll('[data-map]');
    if (!this.maps[0]) return;

    // this.APIKey = 'APIKey';

    fetch('/get-google-maps-api-key')
      .then(response => {
        if (response.ok) {
          return response.text();
        } else {
          return '';
        }
      })
      .then((apiKey) => {
        if (!apiKey) {
          throw new Error('Google Maps API key is not available.');
        }
        this.APIKey = apiKey;
        this.loader = new Loader(this.APIKey, { region: "SA" });
        this.init();
      })
      .catch((error) => {
        console.error('Error:', error.message);
      });

    this.styles = [
      {
        elementType: 'geometry.fill',
        stylers: [{ saturation: -100 }],
      },
      {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#5c5c5c' }],
      },
      {
        featureType: 'administrative',
        elementType: 'labels',
        stylers: [{ saturation: -100 }],
      },
      {
        featureType: 'administrative.locality',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#474747' }],
      },
      {
        featureType: 'landscape',
        elementType: 'labels.icon',
        stylers: [{ color: '#9c9c9c' }],
      },
      {
        featureType: 'landscape',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#454545' }],
      },
      {
        featureType: 'landscape.man_made.building',
        elementType: 'geometry',
        stylers: [{ lightness: -4 }, { saturation: -100 }],
      },
      {
        featureType: 'landscape.man_made.business_corridor',
        elementType: 'geometry',
        stylers: [{ color: '#e3e3e3' }, { visibility: 'off' }],
      },
      {
        featureType: 'poi',
        elementType: 'labels.icon',
        stylers: [{ color: '#9c9c9c' }],
      },
      {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#6b6b6b' }],
      },
      {
        featureType: 'poi.business',
        elementType: 'geometry',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'poi.medical',
        elementType: 'geometry',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'poi.school',
        elementType: 'geometry',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'poi.sports_complex',
        elementType: 'geometry',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: '#ffffff' }],
      },
      {
        featureType: 'road',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#e0e0e0' }],
      },
      {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [{ saturation: -100 }],
      },
      {
        featureType: 'road',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#7a7a7a' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#828282' }],
      },
      {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#383838' }],
      },
      {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [{ lightness: 25 }, { saturation: -100 }],
      },
      {
        featureType: 'transit',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#ffffff' }, { saturation: -100 }],
      },
      {
        featureType: 'transit',
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#ffffff' }],
      },
      {
        featureType: 'transit.station',
        elementType: 'labels.text',
        stylers: [{ color: '#8f8f8f' }],
      },
      {
        featureType: 'transit.station',
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#f5f5f5' }],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: '#d4d4d4' }],
      },
      {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#ffffff' }],
      },
    ];

    return true;
  }

  async init() {
    const google = await this.loader.load();

    this.maps.forEach((map) => {
      const { lat, lng, text } = map.dataset;

      if (!lat || !lng) return false;

      const gMap = new google.maps.Map(map, {
        center: {
          lat: Number(lat),
          lng: Number(lng),
        },
        zoom: 15,
        disableDefaultUI: true,
        zoomControl: true,
        styles: this.styles,
      });

      gMap.setOptions({ minZoom: 5, maxZoom: 20 });

      if (text) {
        const marker = new google.maps.Marker({
          position: {
            lat: Number(lat),
            lng: Number(lng),
          },
          map: gMap,
          // icon: this.marker,
          title: 'Address',
        });

        const infowindow = new google.maps.InfoWindow({
          content: text,
          ariaLabel: 'Location',
          pixelOffset: new google.maps.Size(0, 40),
        });

        marker.addListener('click', () => {
          infowindow.open({
            anchor: marker,
            map,
          });
        });
        if (infowindow) {
          infowindow.open({
            anchor: marker,
            map: map
          });
        }
      }

    });

  }
}
