import Entities from './Entities';
import { isRtl } from '../helpers/document';

class BeforeAfterEntity {
  constructor(rootEl) {
    if (!this.setVars(rootEl)) return;

    this.bindEvents();
  }

  setVars(rootEl) {
    this.rootEl = rootEl;
    if (!this.rootEl) return;

    this.before = this.rootEl.querySelector('[data-before-image]');
    this.beforeImg = this.before.querySelector('img');
    if (!this.beforeImg) return;

    this.after = this.rootEl.querySelector('[data-after-image]');
    this.afterImg = this.after.querySelector('img');
    if (!this.afterImg) return;

    this.inputEl = this.rootEl.querySelector('[data-before-after-input]');
    if (!this.inputEl) return;

    this.buttonEl = this.rootEl.querySelector('[data-before-after-button]');
    if (!this.buttonEl) return;

    this.active = false;

    return true;
  }

  bindEvents() {
    window.addEventListener('load', this.onLoad.bind(this));
    this.inputEl.addEventListener('input', this.onMove.bind(this));
  }

  onLoad() {
    const sliderPos = this.inputEl.value;
    this.after.style.width = `${sliderPos}%`;
    if (isRtl) {
      this.buttonEl.style.right = `calc(${sliderPos}% - 18px)`;
    }
    else {
      this.buttonEl.style.left = `calc(${sliderPos}% - 18px)`;
    }

    const width = this.rootEl.offsetWidth;
    this.beforeImg.style.width = `${width}px`;
    this.afterImg.style.width = `${width}px`;
  }

  onMove(e) {
    const sliderPos = e.target.value;
    this.after.style.width = `${sliderPos}%`;
    if (isRtl) {
      this.buttonEl.style.right = `calc(${sliderPos}% - 18px)`;
    }
    else {
      this.buttonEl.style.left = `calc(${sliderPos}% - 18px)`;
    }
  }

  destroy() {
    if (this.swiper) this.swiper.destroy();
  }
}

export default class BeforeAfter {
  constructor() {
    this.entities = new Entities(
      'BeforeAfter',
      '[data-before-after]',
      BeforeAfter.initSingle,
      BeforeAfter.destroySingle,
    );
  }

  static initSingle(element) {
    return new BeforeAfterEntity(element);
  }

  static destroySingle({ entityObj }) {
    entityObj.destroy();
  }
}
