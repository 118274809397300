import Swiper from 'swiper';
import Entities from './Entities';
import { each } from '../helpers/array';

class GalleryExpandableEntity {
  constructor(rootEl) {
    if (!this.setVars(rootEl)) return;

    this.bindEvents();
  }

  setVars(rootEl) {
    this.rootEl = rootEl;
    if (!this.rootEl) return;

    this.items = this.rootEl.querySelectorAll('[data-gallery-expandable-item]');
    if (!this.items) return;

    this.classes = {
      activeItem: 'galleryExpandable__item--active',
    };

    return true;
  }

  bindEvents() {
    each(this.items, (item) => {
      item.addEventListener('click', this.onButtonClick.bind(this));
    });
  }

  onButtonClick(e) {
    const target = e.target.closest('.galleryExpandable__item');
    each(this.items, (item) => {
      if (item === target && target.classList.contains(this.classes.activeItem)) return;
      item.classList.remove(this.classes.activeItem);
    });
    target.classList.toggle(this.classes.activeItem);
  }

  destroy() {
    if (this.swiper) this.swiper.destroy();
  }
}

export default class GalleryExpandable {
  constructor() {
    this.entities = new Entities(
      'GalleryExpandable',
      '[data-gallery-expandable]',
      GalleryExpandable.initSingle,
      GalleryExpandable.destroySingle,
    );
  }

  static initSingle(element) {
    return new GalleryExpandableEntity(element);
  }

  static destroySingle({ entityObj }) {
    entityObj.destroy();
  }
}
