import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import Entities from './Entities';

class TestimonialsSliderEntity {
  constructor(rootEl) {
    if (!this.setVars(rootEl)) return;

    this.initSwiper();
  }

  setVars(rootEl) {
    this.rootEl = rootEl;
    if (!this.rootEl) return;

    this.sliderEl = this.rootEl.querySelector('[data-testimonials-slider-swiper]');
    if (!this.sliderEl) return;

    this.paginationEl = this.rootEl.querySelector('[data-testimonials-slider-pagination]');
    this.nextButtonEl = this.rootEl.querySelector('[data-testimonials-slider-next]');
    this.prevButtonEl = this.rootEl.querySelector('[data-testimonials-slider-prev]');

    return true;
  }

  initSwiper() {
    this.swiper = new Swiper(this.sliderEl, {
      modules: [Navigation, Pagination],
      slidesPerView: 1,
      spaceBetween: 20,
      speed: 1000,
      navigation: {
        nextEl: this.nextButtonEl,
        prevEl: this.prevButtonEl,
      },
      pagination: {
        el: this.paginationEl,
        clickable: true,
      },
      breakpoints: {
        1024: {
          spaceBetween: 0,
        },
      },
    });
  }

  destroy() {
    if (this.swiper) this.swiper.destroy();
  }
}

export default class TestimonialsSlider {
  constructor() {
    this.entities = new Entities(
      'TestimonialsSlider',
      '[data-testimonials-slider]',
      TestimonialsSlider.initSingle,
      TestimonialsSlider.destroySingle,
    );
  }

  static initSingle(element) {
    return new TestimonialsSliderEntity(element);
  }

  static destroySingle({ entityObj }) {
    entityObj.destroy();
  }
}
