import Swiper from 'swiper';
import Entities from './Entities';
import { each } from '../helpers/array';

class AreasDevelopmentEntity {
  constructor(rootEl) {
    if (!this.setVars(rootEl)) return;

    // this.initSwiper();
    this.bindEvents();
  }

  setVars(rootEl) {
    this.rootEl = rootEl;
    if (!this.rootEl) return;

    this.sliderEl = this.rootEl.querySelector('[data-area-development-swiper]');
    if (!this.sliderEl) return;

    this.itemsArr = this.rootEl.querySelectorAll('[data-area-development-item]');
    if (!this.itemsArr) return;

    this.classes = {
      activeItem: 'areasDevelopment__item--active',
    };

    return true;
  }

  initSwiper() {
    this.swiper = new Swiper(this.sliderEl, {
      slidesPerView: 4,
      spaceBetween: 0,
      speed: 1000,
      freeMode: true, 
      breakpoints: {
        768: {
          slidesPerView: 6,
        },
        1280: {
          slidesPerView: 9,
        },
      }
    });
  }

  bindEvents() {
    each(this.itemsArr, (item) => {
      item.addEventListener('click', this.onButtonClick.bind(this));
    });
  }

  onButtonClick(e) {
    if (e.target.closest('.areasDevelopment__item').classList.contains(this.classes.activeItem)) {
      e.target.closest('.areasDevelopment__item').classList.remove(this.classes.activeItem);
    }
    else {
      each(this.itemsArr, (item) => {
        item.classList.remove(this.classes.activeItem);
      });
      e.target.closest('.areasDevelopment__item').classList.add(this.classes.activeItem);
    }
  }

  destroy() {
    if (this.swiper) this.swiper.destroy();
  }
}

export default class AreasDevelopment {
  constructor() {
    this.entities = new Entities(
      'AreasDevelopment',
      '[data-area-development]',
      AreasDevelopment.initSingle,
      AreasDevelopment.destroySingle,
    );
  }

  static initSingle(element) {
    return new AreasDevelopmentEntity(element);
  }

  static destroySingle({ entityObj }) {
    entityObj.destroy();
  }
}
