/* eslint-disable arrow-body-style */
/* eslint-disable class-methods-use-this */
import anime from 'animejs/lib/anime.es';

export default class InitiativesSection {
  constructor() {
    if (!this.setVars()) return;

    this.setEvents();
  }

  setVars() {
    this.rootEl = document.querySelector('[data-initiatives]');
    if (!this.rootEl) return;

    this.buttonsArr = this.rootEl.querySelectorAll('.initiativesSection__navItem');
    if (!this.buttonsArr) return false;

    this.contentWrapEl = this.rootEl.querySelector('.initiativesSection__items');
    if (!this.contentWrapEl) return false;

    this.contentsArr = this.contentWrapEl.querySelectorAll('.initiativesSection__item');
    if (!this.contentsArr) return false;

    this.classes = {
      buttonActive: 'initiativesSection__navItem--active',
      contentActive: 'initiativesSection__item--active',
      contentPrev: 'initiativesSection__item--prev',
    };

    this.prevContentEl = Array.prototype.filter.call(this.contentsArr, (content) => {
      return content.classList.contains(this.classes.contentActive);
    })[0] || this.contentsArr[0];

    return true;
  }

  setEvents() {
    this.onButtonClick = this.onButtonClick.bind(this);
    Array.prototype.forEach.call(this.buttonsArr, (buttonEl) => {
      buttonEl.addEventListener('click', this.onButtonClick);
    });
  }

  onButtonClick(e) {
    e.preventDefault();
    const buttonEl = e.currentTarget;
    const tabId = buttonEl.getAttribute('data-initiatives-tab');

    const contentEl = Array.prototype.filter.call(this.contentsArr, (content) => {
      return content.getAttribute('data-initiatives-tab') === tabId;
    })[0];

    // change button
    this.unactivateAllButtons();
    buttonEl.classList.add(this.classes.buttonActive);

    // change content
    this.goToTab(contentEl);
    console.log(contentEl);
  }

  unactivateAllButtons() {
    Array.prototype.forEach.call(this.buttonsArr, (buttonEl) => {
      buttonEl.classList.remove(this.classes.buttonActive);
    });
  }

  goToTab(contentEl) {
    if (contentEl.classList.contains(this.classes.contentActive)) return;

    const { prevContentEl } = this;
    this.prevContentEl = contentEl;

    anime.remove(this.contentWrapEl);
    anime.remove(prevContentEl);
    anime.remove(contentEl);

    anime.set(this.contentWrapEl, { height: `${prevContentEl.scrollHeight}px` });
    anime.set(prevContentEl, { opacity: 1 });
    anime.set(contentEl, { opacity: 0 });

    prevContentEl.classList.add(this.classes.contentPrev);
    prevContentEl.classList.remove(this.classes.contentActive);
    contentEl.classList.add(this.classes.contentActive);

    anime({
      targets: this.contentWrapEl,
      height: `${contentEl.scrollHeight}px`,
      duration: 400,
      easing: 'easeOutCubic',
      complete: () => {
        this.contentWrapEl.style.height = '';
      },
    });

    anime({
      targets: prevContentEl,
      opacity: 0,
      duration: 400,
      easing: 'easeOutCubic',
      complete: () => {
        prevContentEl.classList.remove(this.classes.contentPrev);
        prevContentEl.style.opacity = '';
      },
    });

    anime({
      targets: contentEl,
      opacity: 1,
      duration: 400,
      easing: 'easeOutCubic',
      complete: () => {
        contentEl.style.opacity = '';
      },
    });
  }

  showItem(itemObj) {

    anime.remove(itemObj);
    anime.set(itemObj, {
      height: 0,
      opacity: 0,
      marginTop: 0,
    });

    anime({
      targets: itemObj,
      height: itemObj.scrollHeight,
      marginTop: '12px',
      opacity: 1,
      easing: 'easeInOutCubic',
      duration: 600,
    });
  }

  hideItem(itemObj) {

    anime.remove(itemObj);
    anime({
      targets: itemObj,
      height: 0,
      marginTop: 0,
      opacity: 0,
      easing: 'easeInOutCubic',
      duration: 600,
    });
  }
}
